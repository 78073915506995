import React, { useState } from 'react';
import styled from 'styled-components';

import { containerMixin, responsiveQuery } from '../Layouts/LayoutWrap/GlobalStyles';

import Seo from '../Misc/Seo/Seo';
import MainLayout from '../Layouts/MainLayout/MainLayout';
import Slider from '../Slider/Slider';
import SingleProjectImages from './SingleProjectImages';
import { Heading, SectionHeader } from '../Misc/Typogrpahy/Typography';

const SectionContent = styled.div`
  ${containerMixin};
`;
const SingleProjectSection = styled.section`
  padding-top: 10rem;
  padding-bottom: 10rem;
  ${responsiveQuery.mobileL`
    padding-top: 9rem;
  `}
`;


const SingleProject = ({ name, data }) => {
  const [showSlider, setShowSlider] = useState(false);

  return (
    <MainLayout>
      <Seo title={`${name} | Projects`} />
      <SingleProjectSection>
        <SectionContent>
          <SectionHeader>
            <Heading as='h2'>{name}</Heading>
          </SectionHeader>
          {
            !showSlider && (
              <SingleProjectImages data={data} setShowSlider={setShowSlider} />
            )
          }

          { showSlider && (<Slider data={data} type={2} />) }
        </SectionContent>
      </SingleProjectSection>
    </MainLayout>
  )
};

export default SingleProject;
