import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Misc/Image/Image';
import { ProjectsList, ProjectsItem } from '../../styled/index.styled';

import SingleProjectImagesStyled from './SingleProjectImages.styled';

const SingleProjectImages = ({ data, setShowSlider }) => {
  return (
    <SingleProjectImagesStyled>
      <ProjectsList>
        {
          data.map(({ title, image }, index) => {
            console.log('image', image);
            return (
              <ProjectsItem
                className="single-image-wrapper" key={`${title}_${index}`}
                onClick={() => { setShowSlider(true); }}
              >
                <Image filename={image} />
              </ProjectsItem>
            )
          })
        }
      </ProjectsList>
    </SingleProjectImagesStyled>
  );
};

SingleProjectImages.propTypes = {
  data: PropTypes.array.isRequired,
  setShowSlider: PropTypes.func.isRequired,
};

export default SingleProjectImages;
